import React from 'react';
import { Fieldset } from 'primereact/fieldset';
import { Avatar } from "primereact/avatar";

export const LandingComponents = () => {

    const header = (
        <div className="flex align-items-center gap-2 px-2">
            <Avatar image={"./headshot.png"} size="xlarge" shape="circle" />
            <span className={"font-bold"}>McWelling H Todman</span>
        </div>
    )
    const formattedText = `Hi, my name is McWelling Todman. I am a Software Engineer located in New York, USA.
    I have experience in full-stack web development, data analytics, and consulting. If you are interested in working together, please 
    feel free to get in touch using one of the links below.`
    return (
        <div className={"card"}>
            <Fieldset legend={header} >
                <p className={"m-0"}>
                    {formattedText}
                </p>
            </Fieldset>
        </div>
    )
}
