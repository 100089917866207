import React from 'react';
import './App.css';
import 'primeicons/primeicons.css';
import { LandingComponents } from "./containers/LandingComponents";
import { SocialComponents } from "./containers/SocialComponents";

function App() {
  return (
    <div className="App">
        <header className="App-header">
            <LandingComponents />
        </header>
        <footer className="App-footer">
            <SocialComponents />
        </footer>
    </div>
  );
}

export default App;
