import React, { useState } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import {Sidebar} from "primereact/sidebar";

export const SocialComponents = ({}) => {
    const [visible, setVisible] = useState(false);
    const sidebarText = `This is a single-page React.js web application served to a cloudflare managed 
    domain from AWS Cloudfront. It is deployed via AWS CodePipeline to a S3 bucket. All components were provided by PrimeReact.`;

    const startComponents = (
        <>
            <Button
                className={"resume-button"}
                icon={"pi pi-file"}
                tooltip={"Download Resume"}
                tooltipOptions={{position: 'top'}}
                severity={"help"}
                onClick={() => window.open('https://drive.google.com/file/d/1Q98EFDQ7bXt-KMxMJIo77nzLWAd78gqT/view?usp=drive_link', '_blank')}
            />
        </>
    )

    const centerComponents = (
        <>
            <Button
                className={"contact-button"}
                icon={"pi pi-envelope"}
                rounded={true}
                severity={"warning"}
                tooltip={"Email"}
                tooltipOptions={{position: 'top'}}
                onClick={() => window.location.href="mailto:contact@mcwelling.dev"}
            />
            <Button
                className={"contact-button"}
                icon={"pi pi-github"}
                rounded={true}
                severity={"secondary"}
                tooltip={"Github"}
                tooltipOptions={{position: 'top'}}
                onClick={() => window.open('https://github.com/mcwelling', '_blank')}
            />
            <Button
                className={"contact-button"}
                icon={"pi pi-linkedin"}
                rounded={true}
                severity={"info"}
                tooltip={"LinkedIn"}
                tooltipOptions={{position: 'top'}}
                onClick={() => window.open('https://www.linkedin.com/in/mcwelling/', '_blank')}
            />
        </>
    )
    const endComponents = (
        <>
            <Sidebar
                visible={visible}
                onHide={() => setVisible(false)}
                position={"right"}
            >
                <h2>What am I looking at?</h2>
                <p>{sidebarText}</p>
            </Sidebar>
            <Button
                className={"what-button"}
                icon={"pi pi-question-circle"}
                severity={"success"}
                rounded={true}
                tooltip={"Curious?"}
                tooltipOptions={{position: "top"}}
                onClick={() => setVisible(true)}
            />
        </>
    )

    return (
        <Toolbar
            start={startComponents}
            center={centerComponents}
            end={endComponents}
            className={"social-toolbar"}
        />
    )
}
